import { Link } from "react-router-dom";
import classNames from "classnames";
import { hasPrivilege } from "../../../../Helper";

const SalesTab = (props) => {
  return (
    <div className="row no-gutters">
      <div className="col-md-auto ">
        <div className="toolbar-section">
          <div className="row no-gutters">
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button", {
                  disabled: !hasPrivilege("Sales", 200),
                })}
                to="/dashboard/sales"
              >
                <img src="/img/icons/quotation-md.png" alt="dashboard" />
                <span className="text">Sale Orders</span>
              </Link>
            </div>
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Sales", 201),
                })}
                to="/dashboard/sales/so-new"
              >
                <img src="/img/icons/quotation.png" alt="receive cash" />
                <span className="text"> New Sale Order </span>
              </Link>
              <Link
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Sales", 906),
                })}
                to="/dashboard/sales/sales-receipt"
              >
                <img src="/img/icons/receipt.png" alt="sales receipt" />
                <span className="text"> Sales Receipts </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-auto ">
        <div className="toolbar-section">
          <div className="row no-gutters">
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button", {
                  disabled: !hasPrivilege("Sales", 206),
                })}
                to="/dashboard/sales/invoices"
              >
                <img src="/img/icons/invoice.png" alt="invoices" />
                <span className="text">Invoices</span>
              </Link>
            </div>
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Sales", 207),
                })}
                to="/dashboard/sales/invoice-new"
              >
                <img src="/img/icons/quotation.png" alt="invoice" />
                <span className="text"> New Invoice </span>
              </Link>
              <Link
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Sales", 906),
                })}
                to="/dashboard/sales/sales-receipt"
              >
                <img src="/img/icons/receive-cash.png" alt="sales receipt" />
                <span className="text"> Receive Payments </span>
              </Link>
            </div>
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Sales", 211),
                })}
                to="/dashboard/sales/deleted-invoices"
              >
                <img src="/img/icons/quotation.png" alt="receive cash" />
                <span className="text"> Deleted Invoices </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesTab;
